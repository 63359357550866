/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import DashBoardModel from "../../../../../models/gc/DashBoardModel";

type Props = {
  className: string
}

type TypeInvoices = {
  [label: string]: any
}

const LastInvoicesActivitiesOnThisDay: React.FC<Props> = ({className}) => {
  const [loading, setLoading] = useState(true)
  const [invoicesActivities, setInvoicesActivities] = useState<Array<TypeInvoices>>([])
    
  const getInvoicesActivities = async () => {
    let dataModel = new DashBoardModel()
    const {data} = await dataModel.getSectionInvoicesLastActivities()
    if (data.type === "success") {
      setInvoicesActivities(prevState => data.result)
    } else
      setInvoicesActivities(prevState => [])
    setLoading(prevState => false)
  }

  useEffect(() => {
    getInvoicesActivities()
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder fs-2 text-dark'>
            Activités des factures de la journée
          </span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Timeline */}
        <div className='timeline-label'>
        {
          invoicesActivities.length > 0 
          ? 
          invoicesActivities.map((invoicesActivity, invoicesActivityIndex) => {
          return (
            <>
              <div key={invoicesActivityIndex + "_invoicesActivity"} className='timeline-item'>
                <div className='timeline-label fw-bolder text-gray-800 fs-8'>
                  {invoicesActivity?.hour_created_at}
                </div>

                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-info fs-1'></i>
                </div>

                <div className='fw-mormal timeline-content text-muted fs-8'>
                  <span className='fw-bolder text-gray-800 ps-2 fs-8'>
                    {invoicesActivity?.num_invoice}
                  </span>
                  
                  <span className='fw-bolder ps-2 fs-8'>
                    <a href={void(0)} className='text-success'>
                      ({invoicesActivity?.amount_ht+' '+invoicesActivity?.currency_code})
                    </a>
                  </span>

                  <span className='fw-bolder ps-2 fs-8'>-</span>

                  <span className='fw-bolder ps-2 fs-8'>
                    {invoicesActivity?.gc_client_nom_client} {'['+invoicesActivity?.gc_client_ville_client+']'}
                  </span>
                </div>
              </div>
            </>
          )})
          :
          <>
          <p className='fw-bolder text-muted text-center'>
            Aucune activité éffectuée ...
          </p>
          </>
        }
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}

export {LastInvoicesActivitiesOnThisDay}
