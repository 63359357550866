import {FC} from 'react'
import {DataProtectionsView} from './DataProtectionsView'

const DataProtections: FC = () => {
  return (
    <>
      <DataProtectionsView className='mb-5 mb-xl-8' />
    </>
  )
}

export {DataProtections}
