/* eslint-disable react/jsx-no-target-blank */
//import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
//import { CheckAccess } from '../../../../app/Utilities';

export function AsideMenuMainRE() {

  return (
    <>
      <AsideMenuItem
        to='/reporting/dashboard'
        icon='/media/icons/duotone/Home/Home.svg'
        title='Tableau de bord'
        fontIcon='bi-home'
        method={"dashboard-access"}
      />

      {/*  */}
      <div className='menu-item'>
        <div className='menu-content'>
          {/* <div className='separator mx-1 my-4'></div> */}
        </div>
      </div>
    </>
  )
}
