import Model from "./Model";

class AuthModel extends Model {

    private prefix = ""

    initLogin(data: {}) {
        return this.post(this.prefix + `/init-login`, data)
    }

    login(data: {chooseApp: any, login: any, password: any, chooseDepartment: any}) {
        return this.post(this.prefix + `/login`, data)
    }

    logout() {
        return this.get(this.prefix + `/logout`)
    }

    forgot(data: {email: any}) {
        return this.post(this.prefix + `/forgot`, data)
    }

    getDepartments() {
        return this.get(this.prefix + `/login/departments`)

    }
    
}

export default AuthModel
