import {FC} from 'react'

import {StatusInSuiviPrestationView} from "./status/StatusInSuiviPrestationView";
import {PrestationsView} from './PrestationsView'

import {OutTimeOrdersView} from './out_time_orders/OutTimeOrdersView'

const Prestations: FC = () => {
  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 gx-xl-12 mb-5'>
        <div className='col-xxl-12'>
          <StatusInSuiviPrestationView className='card-xl-stretch mb-xxl-8' bgHeaderColor={'orange'}/>
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-12'>
        <div className='col-xxl-12'>
          <PrestationsView className='mb-5 mb-xl-8' />
        </div>

        <div className='col-xxl-12'>
          <OutTimeOrdersView className='mb-5 mb-xl-8' />
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export {Prestations}
