/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Modal} from 'bootstrap';

import {CheckAccess, currentTime} from '../../../../Utilities';
import DataTable from '../../../../../services/Table/DataTable'
import * as columnFormatters from "../../../../../services/Table/column-formatters";
import {ExportToExcelTable} from "../../../../../services/Excel/ExportToExcel";

import {PrestationModal} from './PrestationModal'
import SuiviPrestationModel from "../../../../../models/se/SuiviPrestationModel";

type Props = {
  className: string
}

const PrestationsView: React.FC<Props> = ({className}) => {
  const [tableReload, setTableReload] = useState(currentTime)

  const [commandeId, setCommandeId] = useState(null)
  const modalActions = {
    open : (id : any) => {
      setCommandeId(prevState => id)
      // @ts-ignore
      let myModal = new Modal(document.getElementById('prestation_modal'), {});
      myModal.show();
    },
    close : () => {
      setCommandeId(prevState => null)
    }
  }

  const ActionEvents = {
    openInstrumentsList: async (id: any) => {
      modalActions.open(id)
    },
  }

  const columns = [
    {
      dataField: "gc_commande.num_bon",
      text: "N° BC",
      sort: true,
      search: {},
      render: (row: any, rowIndex: any) => {
        return (
          <>{row?.gc_commande_num_bon}</>
        )
      }
    },
    {
      dataField: "gc_client.nom_client",
      text: "Client",
      sort: true,
      search: {},
      render: (row: any, rowIndex: any) => {
        return (
          <>{row?.gc_client_nom_client}</>
        )
      }
    },
    {
      dataField: "gc_client.address_client",
      text: "Adresse",
      sort: true,
      search: {},
      render: (row: any, rowIndex: any) => {
        return (
          <>{row?.gc_client_address_client}</>
        )
      }
    },
    {
      dataField: "date_debut_planning",
      text: "Date de début",
      sort: true,
      search: {},
    },
    {
      dataField: "date_fin_planning",
      text: "Date de fin",
      sort: true,
      search: {},
    },

    {
      dataField: "quota_suivi",
      text: "Quota",
    },
    {
      dataField: "action",
      text: "Actions",
      classes: "text-right pr-0",
      style: {},
      formatter: columnFormatters.ActionsColumnFormatterParams,
      formatExtraData: [
        {
          title : "Afficher la liste des instruments",
          className : "btn btn-icon btn-light-primary btn-hover-primary btn-sm me-1",
          svg : {
            path : "/media/icons/duotone/General/Settings-1.svg",
            className : "svg-icon-primary"
          },
          hasAccess : CheckAccess("department-edit"),
          onClick : ActionEvents.openInstrumentsList,
          field : 'commande_id'
        },
      ],
      headerClasses: "text-right pr-3 w-5",
      headerStyle: {
        width: "5%"
      },
    },
  ];

  const filename = ""+currentTime();
  const exportToExcel = () => {
    (async () => {
      await ExportToExcelTable("suivi_prestation_"+filename, new SuiviPrestationModel(), "", columns, {per_page:"all"});
    })()
  }

  return (
    <>
    {/* begin::Status description */}
    <div className='card mb-3'>
      <div className='card-body d-flex align-items-center py-4'>
        <div className='row'>
          <p className='list-unstyled text-gray-600 fs-6 p-0 m-1'>
            <span className="badge badge-danger">0</span>
            <span className="ms-4">Si la date actuelle dépasse la date de fin planning et que l'état de la commande n'est pas CLOTURE. Sa valeur est 2</span>
          </p>
          <p className='list-unstyled text-gray-600 fs-6 p-0 m-1'>
            <span className="badge badge-warning">0</span>
            <span className="ms-4">Si la date actuelle est égale à la date de fin planning et que l'état de la commande n'est pas CLOTURE. Sa valeur est 1</span>
          </p>
        </div>
      </div>
    </div>
    {/* end::Status description */}

    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-2 mb-1'>
            Liste de suivi des prestations
          </span>
        </h3>
        <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover'>
          {
            <a href={void(0)} onClick={() => exportToExcel()} className="btn btn-sm btn-light-success ms-2">
              <i className="fas fa-file-excel"></i> Exporter
            </a>
          }
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          <DataTable
            tableReload={tableReload}
            dataModel={new SuiviPrestationModel()}
            columns={columns}
          />
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>

    <PrestationModal commandeId={commandeId} modalActions={modalActions} setTableReload={setTableReload} />
    </>
  )
}

export {PrestationsView}
