/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import DashBoardModel from "../../../../../models/gp/DashBoardModel";

type Props = {
    className: string,
    cdi: any,
}

const initStatus = {
    update: 0,
    update_percent: 0,
    not_update: 0,
    not_update_percent: 0,
    total: 1
}

const CountVerificationOutOrUpDate: React.FC<Props> = ({className, cdi}) => {

    const [status, setStatus] = useState(initStatus)


    const getStatus = async () => {
        let dataModel = new DashBoardModel()
        const {data} = await dataModel.getComponentCountVerificationOutOrUpDate()
        //console.log(data);
        {
            if (data.type === "success") {
                return {
                    update: data.result.update,
                    update_percent: data.result.update_percent,
                    not_update: data.result.not_update,
                    not_update_percent: data.result.not_update_percent,
                    total: data.result.total,
                }
            } else {
                //setStatus(initStatus)
                return initStatus
            }
        }

    }

    useEffect(() => {
        let isCancelled = false
        getStatus().then((response) => {
            if (!isCancelled)
                setStatus(prevState => response)
        });
        return () => {
            isCancelled = true
        }
    }, [cdi])

    return (
        <>
            {/* begin::Col */}
            <div className='col bg-light-info px-6 py-8 rounded-2 mb-7 ms-2 me-2'>
                <div className='d-flex align-items-center'>
                    <KTSVG path='/media/icons/duotone/Design/Layers.svg'
                           className='svg-icon-3x svg-icon-info d-block my-2 me-2'/>

                    <div className='text-info fw-bold fw-bolder fs-2'>
                        Etat des vérifications interne
                    </div>
                </div>

                <div className='d-flex align-items-center flex-column mt-5'>
                    <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
            <span className='fw-bold fs-6 text-success'>
              Nombre de verifications à jour :
            </span>
                        <span className='fw-bold text-success fs-3'>
              {status.update}
            </span>
                    </div>
                    <div className='h-20px mx-3 w-100 bg-white mb-3'>
                        <div className='bg-success rounded h-20px' role='progressbar'
                             style={{width: status.update_percent + "%"}}>
                            <div className='fw-bold text-white text-center'>
                                {status.update_percent + "%"}
                            </div>
                        </div>
                    </div>

                    <div className='mx-3 w-100'>
                        <hr className='rounded h-2px'/>
                    </div>

                    <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
            <span className='fw-bold fs-6 text-danger'>
              Nombre de verifications en retard :
            </span>
                        <span className='fw-bold fs-3 text-danger'>
              {status.not_update}
            </span>
                    </div>
                    <div className='h-20px mx-3 w-100 bg-white mb-3'>
                        <div className='bg-danger rounded h-20px' role='progressbar'
                             style={{width: status.not_update_percent + "%"}}>
                            <div className='fw-bold text-white text-center'>
                                {status.not_update_percent + "%"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* end::Col */}
        </>
    )
}

export {CountVerificationOutOrUpDate}
