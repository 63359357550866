/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../../../_metronic/helpers'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'

import { CheckAccess } from '../../../../Utilities'
import DashBoardModel from "../../../../../models/gc/DashBoardModel";

import {CountNewCustomersHasQuoteAndOrderInCurrentYear} from './CountNewCustomersHasQuoteAndOrderInCurrentYear'
import {CountLeadsHasQuoteNotOrderInCurrentYear} from './CountLeadsHasQuoteNotOrderInCurrentYear'
import {CountActifCustomersHasOrderInPreviousAndCurrentYear} from './CountActifCustomersHasOrderInPreviousAndCurrentYear'
import {CountAllCustomers} from './CountAllCustomers'

type Props = {
    className: string,
    bgHeaderColor: string,
}

const initSectionCustomersCount = {
    countProspects: 0,
    countActivesCustomers: 0,
    countNewCustomers: 0,
}

const SectionCustomersCountView: React.FC<Props> = ({className, bgHeaderColor}) => {
    const [status, setStatus] = useState(initSectionCustomersCount)
    const getSectionCustomersCount = async () => {
        let dataModel = new DashBoardModel()
        const {data} = await dataModel.getSectionCustomersCount()
        //console.log(data);
        if (data.type === "success") {
            setStatus(prevState => {
                return {
                    countNewCustomers: data.result.countNewCustomers,
                    countProspects: data.result.countProspects,
                    countActivesCustomers: data.result.countActivesCustomers,
                }
            })
        } else {
            setStatus(initSectionCustomersCount)
        }
    }

    useEffect(() => {
        getSectionCustomersCount();
    }, [])

    return (
        <>
            <div className={`card ${className}`}>
                {/* begin::Header */}
                <div className={`card-header border-0 py-5 bg-${bgHeaderColor}`}>
                </div>
                {/* end::Header */}

                {/* begin::Body */}
                <div className='card-body p-0'>
                    {/* begin::Chart */}
                    {/* <div className={`mixed-widget-2-chart card-rounded-bottom bg-${bgHeaderColor}`}></div> */}
                    {/* end::Chart */}

                    {/* begin::Memos */}
                    <div className='card-p mt-n20 position-relative'>
                        {/* begin::Row */}
                        <div className='row g-0'>
                            <CountNewCustomersHasQuoteAndOrderInCurrentYear count={status?.countNewCustomers} className={className}/>
                            
                            <CountLeadsHasQuoteNotOrderInCurrentYear count={status?.countProspects} className={className}/>
                        </div>
                        {/* end::Row */}

                        {/* begin::Row */}
                        <div className='row g-0'>
                            <CountActifCustomersHasOrderInPreviousAndCurrentYear count={status?.countActivesCustomers} className={className}/>
                            
                            <CountAllCustomers count={0} className={className}/>
                        </div>
                        {/* end::Row */}
                    </div>
                    {/* end::Memos */}
                </div>
                {/* end::Body */}
            </div>
        </>
    )
}

export {SectionCustomersCountView}