/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'

import DashBoardModel from "../../../../../../models/se/DashBoardModel";
import {useSafeState} from "../../../../../../services/Hooks/useSafeState";

type Props = {
    className: string,
}

const initStatus = {
    countOrdersNotPlanningInLabAndInSite: 0,
    countOrdersNotYetDeliveries: 0,
}

const StatusInSuiviPlanning: React.FC<Props> = ({className}) => {
    const [status, setStatus] = useSafeState(initStatus)

    const getStatus = async () => {
        let dataModel = new DashBoardModel()
        const {data} = await dataModel.getSectionStatusInSuiviPlanning()
        if (data.type === "success") {
            setStatus({
                countOrdersNotPlanningInLabAndInSite: data.result.countOrdersNotPlanningInLabAndInSite,
                countOrdersNotYetDeliveries: data.result.countOrdersNotYetDeliveries,
            })
        } else {
            setStatus(initStatus)
        }
    }

    useEffect(() => {
        getStatus();
    }, [])

    return (
        <>
            {/* begin::Col */}
            <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7 ms-2 me-2'>
                <div className='d-flex align-items-center'>
                    <KTSVG  path='/media/icons/duotone/Shopping/Cart1.svg' className='svg-icon-3x svg-icon-primary d-block my-2 me-2' />

                    <div className='text-primary fw-bold fw-bolder fs-2'>
                        Etat actuel des commandes
                    </div>
                </div>

                <div className='d-flex align-items-center flex-column mt-5'>
                    <div className='d-flex justify-content-between w-100 mt-auto'>
                        <span className='fw-bold fs-6 text-success'>
                            Nombre de commandes non planifié :
                        </span>

                        <span className='fw-bold text-success fs-3'>
                            {status?.countOrdersNotPlanningInLabAndInSite}
                        </span>
                    </div>

                    <div className='mx-3 w-100'>
                        <hr className='rounded h-2px'/>
                    </div>

                    <div className='d-flex justify-content-between w-100 mt-auto'>
                        <span className='fw-bold fs-6 text-danger'>
                            Nombre de commandes non clôturé :
                        </span>

                        <span className='fw-bold fs-3 text-danger'>
                            {status?.countOrdersNotYetDeliveries}
                        </span>
                    </div>
                </div>
            </div>
            {/* end::Col */}
        </>
    )
}

export {StatusInSuiviPlanning}
