import {useEffect, useState} from "react";

const getPager = (totalItems, currentPage = 1, pageSize = 10, maxPages = 10) => {

    pageSize = (pageSize - 1) + 1
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage, endPage;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
}

const MKPagination = (props) => {

    const [pager, setPager] = useState({}) //paginate(totalItems, currentPage , pageSize , maxPages )
    //const [pf, setPf] = useState(0)

    const setPage = (page,isClick = true) => {
        let _pager = pager
        if (page < 1 || page > _pager.totalPages) {
            return;
        }
        _pager = getPager(props.pagination.totalItems, page, props.pagination.pageSize, props.pagination.maxPages);
        setPager(_pager)

        if (isClick)
            props.onChangePage(page)

    }

    const onChangePageSize = (pageSize) => {
        props.onChangePageSize(pageSize)
    }


    useEffect(() => {
        setPage(1,false)
    }, [props.pagination.totalItems, props.pagination.pageSize, props.pagination.maxPages, props.pagination.currentSort])




    return (
        <>
            {
                //console.log(props.pagination.currentSort)
            }
            <div className="d-flex d-flex justify-content-between flex-wrap">
                <div className="align-items-center flex-wrap pt-0">
                    <select className="form-control"
                            value={props.pagination.pageSize}
                            onChange={(e) => onChangePageSize(e.target.value)}
                            style={{
                                width: 'auto',
                                display: 'inline'
                            }}
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value={props.pagination.totalItems}>Tout</option>
                    </select>

                    <span className="react-bootstrap-table-pagination-total ms-2">
                        Afficher {pager.startIndex + 1} à {pager.endIndex + 1} sur {pager.totalItems} éléments
                    </span>
                </div>

                {
                    (pager?.pages?.length > 1) && (
                        <ul className="pagination ">
                            <li className={(pager.currentPage === 1 ? 'disabled' : '') + ' page-item'}>
                                <a className="page-link" href={void (0)} onClick={() => setPage(1)}>
                                    <i className="fa fa-angle-double-left"></i>
                                </a>
                            </li>
                            <li className={(pager.currentPage === 1 ? 'disabled' : '') + ' page-item'}>
                                <a className="page-link" href={void (0)} onClick={() => setPage(pager.currentPage - 1)}>
                                    <i className="fa fa-angle-left"></i>
                                </a>
                            </li>
                            {pager.pages.map((page, index) =>
                                <li key={index}
                                    className={(pager.currentPage === page ? 'active' : '') + ' page-item'}>
                                    <a className="page-link" onClick={() => setPage(page)}>{page}</a>
                                </li>
                            )}
                            <li className={(pager.currentPage === pager.totalPages ? 'disabled' : '') + ' page-item'}>
                                <a className="page-link" href={void (0)} onClick={() => setPage(pager.currentPage + 1)}>
                                    <i className="fa fa-angle-right"></i>
                                </a>
                            </li>
                            <li className={(pager.currentPage === pager.totalPages ? 'disabled' : '') + ' page-item'}>
                                <a className="page-link" href={void (0)} onClick={() => setPage(pager.totalPages)}>
                                    <i className="fa fa-angle-double-right"></i>
                                </a>
                            </li>
                        </ul>
                    )
                }
            </div>
        </>
    )
}

export default MKPagination