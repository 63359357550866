/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
  className: string
}

const DataProtectionsView: React.FC<Props> = ({className}) => {

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h2 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder text-uppercase fs-1 mb-1'>
              Protection des données personnelles
            </span>
          </h2>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body py-3'>
          <h3 className='card-title fs-3 mt-5'>Honamé Tech limite la collecte de vos données au stricte nécessaire</h3>
          <p className='card-title fs-7'>Honamé Tech est le responsable des traitements de données à caractère personnel mis en œuvre au sein de notre société.</p>
          <p className='card-title fs-7'>A ce titre, elle s’assure que seules les données nécessaires à la réalisation des services rendus soient collectées auprès des prospect, partenaire et client lors de la souscription aux offres de biens et services, pendant toute la durée de notre relation  commerciale ainsi que lors d’opérations de gestion et d’animation  commerciale que nous pouvons réaliser.</p>
          <p className='card-title fs-7'>Tous les formulaires ou dossiers papiers et numériques ont été conçus pour recueillir uniquement les informations nécessaires à l’exécution des opérations et à la réalisation des services.</p>

          <h3 className='card-title fs-3 mt-5'>Honamé Tech traite vos données dans le respect de la légalité</h3>
          <p className='card-title fs-7'>Tous les traitements de données personnelles mis en œuvre par Honamé Tech sont réalisés dans le respect des obligations légales en vigueur. Toute collecte de données répond à une finalité précise.</p>
          <p className='card-title fs-7'>Ainsi, vous pouvez être amené à nous communiquer des données à caractère personnel. Celles-ci sont collectées et traitées par Honamé Tech pour répondre aux demandes exprimées via les formulaires, outils et services mis à votre disposition, exécuter une obligation contractuelle ou pré contractuelle ou respecter nos obligations légales.</p>
          <p className='card-title fs-7'>Pour certains traitements (notamment pour des traitements de prospection commerciale), votre consentement explicite est nécessaire. Une mention dédiée à cet effet sera présente sur le support de collecte. Vous pourrez retirer votre consentement à tout moment sans que cela n’impacte vos relations avec Honamé Tech.</p>
          <p className='card-title fs-7'>Les données collectées sont appelées à être utilisées principalement dans le cadre de gestion des comptes ou des produits et services souscrits, la prospection commerciale, la réalisation d’animations commerciales et de campagnes publicitaires.</p>
          <p className='card-title fs-7'>De plus, les cookies présents sur notre site ont pour finalité de permettre le bon fonctionnement du site et d’en mesurer les accès.</p>

          <h3 className='card-title fs-3 mt-5'>Honamé Tech ne conserve vos données que le temps nécessaire au traitement pour lequel elles ont été collectées</h3>
          <p className='card-title fs-7'>Lorsque vos données personnelles ne sont plus nécessaires pour la réalisation des finalités définies et services attendus, seules sont conservées les informations soumises à une obligation d’archivage légal ou celles pour lesquelles vous nous avez donné votre consentement pour les conserver.</p>

          <h3 className='card-title fs-3 mt-5'>Honamé Tech sécurise vos données</h3>
          <p className='card-title fs-7'>Les données à caractère personnel que nous collectons sont saisies et traitées dans des systèmes informatiques placés sous la responsabilité de Honamé Tech.</p>
          <p className='card-title fs-7'>Notre préoccupation est de préserver la confidentialité, la qualité et l’intégrité de vos données. Les moyens techniques mis en œuvre respectent les meilleures pratiques de sécurité en vigueur. Les technologies et les politiques de sécurité appliquées permettent ainsi de protéger vos données à caractère personnel contre tout accès non autorisé ou toute utilisation impropre.</p>
          <p className='card-title fs-7'>Seuls les personnes habilitées peuvent avoir accès à vos données et sont soumis à des obligations de confidentialité imposées par la politique interne en la matière de sécurité.</p>

          <h3 className='card-title fs-3 mt-5'>Honamé Tech ne transmet vos données qu’à des destinataires habilités</h3>
          <p className='card-title fs-7'>Honamé Tech est tenue au respect du secret professionnel, dans la mesure de la réglementation en vigueur. Aucune information ne sera communiquée aux tiers sauf accord exprès et préalable de votre part ou si la loi en fait obligation à notre société, notamment vis-à-vis des autorités dûment habilitée.</p>
          <p className='card-title fs-7'>Vos données à caractère personnel sont susceptibles d’être communiquées aux services internes de Honamé Tech, à ses sous-traitants et partenaires pour vous permettre de bénéficier des avantages de ces partenariats. Certaines données peuvent également être remises, sur demande, à des tiers autorisés par la loi dans le respect des obligations légales en vigueur.</p>
          <p className='card-title fs-7'>En raison de la dimension internationale de Honamé Tech et afin d'optimiser la qualité de service, les communications d'informations visées ci-dessus sont susceptibles d'impliquer des transferts de données à caractère personnel vers des pays d’implantation de la société.</p>

          <h3 className='card-title fs-3 mt-5'>Honamé Tech respecte vos droits</h3>
          <p className='card-title fs-7'>Vous disposez d’un droit d’accès, de rectification ou de suppression, ainsi que d’un droit à la portabilité de vos données ou de limitation du traitement. Vous pouvez également, pour des raisons tenant à votre situation particulière, vous opposer au traitement de vos données.</p>
          <p className='card-title fs-7'>Vous pouvez exercer ces droits en écrivant par lettre simple à Honamé Tech dont l’adresse est la suivante : <span className='fw-bold'>263, 1er et 3ème Etage Zone Industrielle, Mohammedia - MAROC</span> ou en vous adressant à l’adresse suivante : <span className='text-primary'>contact@smart-sentino.com</span></p>
          <p className='card-title fs-7'>Vous disposez également d’un droit de réclamation auprès de la Commission Nationale de contrôle de la protection des Données à caractère Personnel (CNDP).</p>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {DataProtectionsView}
