/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'

const EquipmentAvailablityStatusAllDep: React.FC = () => {
    return (
        <>
            <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-2 mb-1'>
                    Etat de disponibilité des équipements
                </span>
                <span className='text-muted fw-bold fs-2'></span>
            </h3>
            {/* begin::Table container */}
            <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                        <tr className='border'>
                            <th className='text-muted w-250px'>Département</th>
                            <th className='text-muted text-center'>Nombre d'équipement disponible</th>
                            <th className='text-muted text-center'>Nombre d'équipement sortie pour mission</th>
                            <th className='text-muted text-center'>Nombre d'équipement sortie pour raccordement</th>
                        </tr>
                    </thead>
                    {/* end::Table head */}

                    {/* begin::Table body */}
                    <tbody>
                        <tr>
                            <td>
                                <a href={void(0)} className='fw-bolder fs-4 text-dark text-hover-primary text-uppercase'>
                                TEMPERATURE - PYROMETRIE
                                </a>
                            </td>
                            <td className='text-center'>
                                <span className='fw-bold fs-3 text-success'>
                                {39}
                                </span>
                            </td>
                            <td className='text-center'>
                                <span className='fw-bold fs-3 text-primary'>
                                {0}
                                </span>
                            </td>
                            <td className='text-center'>
                                <span className='fw-bold fs-3 text-info'>
                                {0}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    {/* end::Table body */}
                </table>
                {/* end::Table */}
            </div>
            {/* end::Table container */}
        </>
    )
}

export {EquipmentAvailablityStatusAllDep}
