import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {useDispatch} from "react-redux";
import clsx from 'clsx'
import {KTSVG, checkCurrentApp} from '../../../helpers'
import {actions} from '../../../../setup/redux/AppRedux'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  currentApp?:string
}

const MenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  currentApp,
}) => {
  const {pathname} = useLocation()
  const dispatch = useDispatch()

  const onClick = (e:any) => {
    const dataCurrentApp = e.target.getAttribute("data-current-app");
    dispatch(actions.saveCurrentApp(dataCurrentApp))
  }

  return (
    <div className='menu-item me-lg-3'>
      <Link onClick={(e) => {onClick(e)}}
        className={clsx('menu-link py-3', {
          active: checkCurrentApp(currentApp),
        })}
        to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}

        {icon && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}

        {fontIcon && (
          <span className='menu-icon'>
            <i className={clsx('bi fs-3', fontIcon)}></i>
          </span>
        )}

        <span data-current-app={currentApp} className='menu-title'>{title}</span>

        {hasArrow && <span className='menu-arrow'></span>}
      </Link>
    </div>
  )
}

export {MenuItem}
