import {FC} from 'react'
import {GeneralConditionsView} from './GeneralConditionsView'

const GeneralConditions: FC = () => {
  return (
    <>
      <GeneralConditionsView className='mb-5 mb-xl-8' />
    </>
  )
}

export {GeneralConditions}
