/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'

import {Currency} from "../../../../Utilities";
import DashBoardModel from "../../../../../models/gc/DashBoardModel";
import { currentCurrencyName } from '../../../../Types';

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const initialInvoiceTotal = {
  invoiceTotal: 0,
}

const initialGraphData = {
  invoiceData: [],
}

const GraphTurnoverByMonthInCurrentYear: React.FC<Props> = ({className, chartColor, chartHeight}) => {
  const [total, setInvoiceTotal] = useState(initialInvoiceTotal)
  const findInvoiceTotal = async () => {
    let dataModel = new DashBoardModel()
    const {data} = await dataModel.getSectionTotalInvoices()
    if (data.type === "success") {
      setInvoiceTotal(prevState => {
        return ({
          ...initialInvoiceTotal,
          invoiceTotal: data.result.invoiceTotal,
        })
      })
    } else
      setInvoiceTotal(prevState => initialInvoiceTotal)
  }

  useEffect(() => {
    findInvoiceTotal().then((response) => {})
  }, [])

  const chartRef = useRef<HTMLDivElement | null>(null)
  // @ts-ignore
  useEffect(async () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, await chartOptions(chartColor, chartHeight))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex flex-column p-0'>
        {/* begin::Memos */}
        <div className='flex-grow-1 card-p pb-0'>
          <div className='d-flex flex-stack flex-wrap'>
            <div className='me-2'>
              <span className='fw-bolder text-gray-800 d-block fs-3'>
                Chiffre d'affaires (HT) de l'année en cours
              </span>
            </div>

            <div className={`fw-bolder fs-3 text-${chartColor}`}>
              <Currency amount={total?.invoiceTotal} />
            </div>
          </div>
        </div>
        {/* end::Memos */}

        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-7-chart card-rounded-bottom'></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = async (chartColor: string, chartHeight: string): Promise<ApexOptions> => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)

  let graphData = initialGraphData;

  const getGraphData = async () => {
    let result = initialGraphData
    let dataModel = new DashBoardModel()
    await dataModel.getSectionGraphInvoices().then((response: any) => {
      const {data} = response;
      if (data.type === "success") {
        result = {
          ...initialGraphData,
          invoiceData: data.result.invoiceData,
        }
      } else
        result = initialGraphData
    })
    return result
  }

  await getGraphData().then((response) => graphData = response)

  return {
    series: [
      {
        name: "Chiffre d'affaires",
        data: graphData.invoiceData,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      // max: 100,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' '+currentCurrencyName
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
}

export {GraphTurnoverByMonthInCurrentYear}
