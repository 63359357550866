import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'

import {CheckAccess} from '../Utilities';

import {GeneralConditionPage} from '../pages/general_condition/GeneralConditionPage';
import {DataProtectionPage} from '../pages/data_protections/DataProtectionPage';

import {DashboardInstrument} from '../instrument_park/dashboard/DashboardInstrument'
import {DashboardExecution} from '../execution_monitoring/dashboard/DashboardExecution'
import {DashboardBusiness} from '../business_management/dashboard/DashboardBusiness'
import {DashboardReporting} from '../reporting/dashboard/DashboardReporting'

export function PrivateRoutes({currentApp ,currentUser}: any) {
    // Parc instruments
    const AccountPage = lazy(() => import('../accounts/AccountPage'))
    const DepartmentsPage = lazy(() => import('../instrument_park/departments/DepartmentsPage'))
    const MaterialsPage = lazy(() => import('../instrument_park/materials/MaterialsPage'))
    const ProgRaccordementsPage = lazy(() => import('../instrument_park/programmes/raccordements/ProgRaccordementsPage'))
    const ProgVerificationsPage = lazy(() => import('../instrument_park/programmes/verifications/ProgVerificationsPage'))
    const RaccordementsPage = lazy(() => import('../instrument_park/raccordements/RaccordementsPage'))
    const VerificationsPage = lazy(() => import('../instrument_park/verifications/VerificationsPage'))
    const QualificationsPage = lazy(() => import('../instrument_park/qualifications/QualificationsPage'))
    const PersonsPage = lazy(() => import('../instrument_park/persons/PersonsPage'))
    const ServicesProvidersPage = lazy(() => import('../instrument_park/services_providers/ServicesProvidersPage'))
    const SettingsPage = lazy(() => import('../instrument_park/settings/SettingsPage'))

    // Suivi de l'éxecution
    const StatisticsPage = lazy(() => import('../execution_monitoring/statistics/StatisticsPage'))
    const ClientListsPage = lazy(() => import('../execution_monitoring/clients/ClientListsPage'))
    const PrestationsPage = lazy(() => import('../execution_monitoring/prestations/PrestationsPage'))
    const OrdersListsPage = lazy(() => import('../execution_monitoring/orders/OrdersListsPage'))
    const ReceptionsPage = lazy(() => import('../execution_monitoring/receptions/ReceptionsPage'))
    const InterventionsPage = lazy(() => import('../execution_monitoring/interventions/InterventionsPage'))
    const CertificatsPage = lazy(() => import('../execution_monitoring/certificats/CertificatsPage'))
    const ConstatsPage = lazy(() => import('../execution_monitoring/constats/ConstatsPage'))
    const LivraisonsPage = lazy(() => import('../execution_monitoring/livraisons/LivraisonsPage'))

    // Gestion commerciale
    const StatsPage = lazy(() => import('../business_management/stats/StatsPage'))
    const ClientsPage = lazy(() => import('../business_management/clients/ClientsPage'))
    const QuotesPage = lazy(() => import('../business_management/quotes/QuotesPage'))
    const OrdersPage = lazy(() => import('../business_management/orders/OrdersPage'))
    const InvoicesPage = lazy(() => import('../business_management/invoices/InvoicesPage'))
    const RequestContractReviewsPage = lazy(() => import('../business_management/request_contract_reviews/RequestContractReviewsPage'))
    const GcSettingsPage = lazy(() => import('../business_management/settings/GcSettingsPage'))

    type PropsRenderRoute = {
        app: any,
    }

    const RenderRoute = ({app}: any) => {
        console.log(currentApp + " -----")
        switch (app) {
            case 'GP':
                return (
                    <Switch>
                        <Route path='/my-account' component={AccountPage}/>
                        <Route path='/instrument/dashboard' component={DashboardInstrument} exact={true}/>
                        {
                            CheckAccess("department-access") &&
                            <Route path='/instrument/departments' component={DepartmentsPage}/>
                        }
                        {
                            CheckAccess("material-access") &&
                            <Route path='/instrument/materials' component={MaterialsPage}/>
                        }
                        {
                            CheckAccess("program-etalonnage-access") &&
                            <Route path='/instrument/programmes/raccordements' component={ProgRaccordementsPage}/>
                        }
                        {
                            CheckAccess("program-verification-access", "suivi_verif") &&
                            <Route path='/instrument/programmes/verifications' component={ProgVerificationsPage}/>
                        }
                        {
                            CheckAccess("raccordement-access") &&
                            <Route path='/instrument/raccordements' component={RaccordementsPage}/>
                        }
                        {
                            CheckAccess("verification-access", "suivi_verif") &&
                            <Route path='/instrument/verifications' component={VerificationsPage}/>
                        }
                        {
                            CheckAccess("qualification-access", "suivi_qualif") &&
                            <Route path='/instrument/qualifications' component={QualificationsPage}/>
                        }
                        {
                            CheckAccess("person-access") &&
                            <Route path='/instrument/persons' component={PersonsPage}/>
                        }
                        {
                            CheckAccess("service-provider-access", "service_provider") &&
                            <Route path='/instrument/services-providers' component={ServicesProvidersPage}/>
                        }
                        {
                            CheckAccess("setting-access") &&
                            <Route path='/instrument/settings' component={SettingsPage}/>
                        }
                        <Route path='/general-condition' component={GeneralConditionPage}/>
                        <Route path='/data-protection' component={DataProtectionPage}/>
                        
                        <Redirect from="/auth" to='/instrument/dashboard'/>
                        <Redirect from="/" exact to='/instrument/dashboard'/>
                    </Switch>
                );

            case 'SE':
                return (
                    <Switch>
                        <Route path='/my-account' component={AccountPage}/>
                        <Route path='/execution/dashboard' component={DashboardExecution}/>
                        {
                            <Route path='/execution/statistics' component={StatisticsPage}/>
                        }
                        {
                            <Route path='/execution/clients' component={ClientListsPage}/>
                        }
                        {
                            <Route path='/execution/prestations' component={PrestationsPage}/>
                        }
                        {
                            <Route path='/execution/orders' component={OrdersListsPage}/>
                        }
                        {
                            <Route path='/execution/receptions' component={ReceptionsPage}/>
                        }
                        {
                            // <Route path='/execution/planning' component={PlanningPage}/>
                        }
                        {
                            <Route path='/execution/interventions' component={InterventionsPage}/>
                        }
                        {
                            <Route path='/execution/certificats' component={CertificatsPage}/>
                        }
                        {
                            <Route path='/execution/constats' component={ConstatsPage}/>
                        }
                        {
                            <Route path='/execution/livraisons' component={LivraisonsPage}/>
                        }
                        <Route path='/general-condition' component={GeneralConditionPage}/>
                        <Route path='/data-protection' component={DataProtectionPage}/>

                        <Redirect from="/auth" to='/execution/dashboard'/>
                        <Redirect from="/" exact to='/execution/dashboard'/>
                    </Switch>
                );

            case 'GC':
                return (
                    <Switch>
                        <Route path='/my-account' component={AccountPage}/>
                        <Route path='/business/dashboard' component={DashboardBusiness}/>
                        {
                            <Route path='/business/stats' component={StatsPage}/>
                        }
                        {
                            <Route path='/business/clients' component={ClientsPage}/>
                        }
                        {
                            <Route path='/business/quotes' component={QuotesPage}/>
                        }
                        {
                            <Route path='/business/orders' component={OrdersPage}/>
                        }
                        {
                            <Route path='/business/invoices' component={InvoicesPage}/>
                        }
                        {
                            <Route path='/business/request-contract-reviews' component={RequestContractReviewsPage}/>
                        }
                        {
                            <Route path='/business/settings' component={GcSettingsPage}/>
                        }
                        <Route path='/general-condition' component={GeneralConditionPage}/>
                        <Route path='/data-protection' component={DataProtectionPage}/>
                        
                        <Redirect from="/auth" to='/business/dashboard'/>
                        <Redirect from="/" exact to='/business/dashboard'/>
                    </Switch>
                );

            case 'RE':
                return (
                    <Switch>
                        <Route path='/my-account' component={AccountPage}/>
                        <Route path='/reporting/dashboard' component={DashboardReporting}/>
                        {
                            //
                        }
                        <Route path='/general-condition' component={GeneralConditionPage}/>
                        <Route path='/data-protection' component={DataProtectionPage}/>
                        
                        <Redirect from="/auth" to='/reporting/dashboard'/>
                        <Redirect from="/" exact to='/reporting/dashboard'/>
                    </Switch>
                );

            default:
                return <Switch><Redirect to='/'/></Switch>
        }
    }

    return (
        <Suspense fallback={<FallbackView/>}>
            <RenderRoute app={currentApp}/>
        </Suspense>
    )
}
