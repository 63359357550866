import Swal from './sweetalert2'
import './sweetalert2.css'


export const SweetAlert = (type: string, title: string, text: string, confirmFunction: any) => {
    // @ts-ignore
    Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success me-2',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    }).
    fire({
            title: title,
            text: text,
            type: type,
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText:
                '<i style="color:#fff" class="fas fa-check-circle"></i> Valider!',
            cancelButtonText:
                '<i style="color:#fff" class="fa fa-ban"></i> Annuler',
        }
    ).then((result: any) => {
        if (result.value)
            if (typeof confirmFunction === "function")
                confirmFunction()
    })
}


export const SweetAlertInfo = (text: string) => {
    // @ts-ignore
    Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-primary me-2',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false,
        showConfirmButton: false,
        showCloseButton : true,
    }).
    fire({
        html: "<h2>"+text+"</h2>",
    })
}


export const SweetAlertOk = (type: string, title: string, text: string, confirmFunction: any = null) => {
    // @ts-ignore
    Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success me-2',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    }).
    fire({
            title: title,
            text: text,
            type: type,
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText:
                '<i style="color:#fff" class="fas fa-check-circle"></i> OK!',
            cancelButtonText:
                '<i style="color:#fff" class="fa fa-ban"></i> Annuler',
        }
    ).then((result: any) => {
        if (result.value)
            if (typeof confirmFunction === "function")
                confirmFunction()
    })
}