/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'

import {CheckAccess, currentTime} from '../../../../Utilities';
import DataTable from '../../../../../services/Table/DataTable'
import {ExportToExcelTable} from "../../../../../services/Excel/ExportToExcel";

import SuiviPrestationModel from "../../../../../models/se/SuiviPrestationModel";

type Props = {
  commandeId: any
  modalActions : {
    open : (id:any) => void;
    close : () => void;
  }
  setTableReload: any
}

const PrestationModal: React.FC<Props> = ({commandeId, modalActions}) => {
  const [tableReload, setTableReload] = useState(currentTime)
  const [loading, setLoading] = useState(false)

  // @ts-ignore
  const isAddMode = !commandeId;

  const columns = [
    {
      dataField: "certificat",
      text: "N° Certificat",
    },
    {
      dataField: "constat",
      text: "N° Constat",

    },
    {
      dataField: "code",
      text: "Code",
    },
    {
      dataField: "designation",
      text: "Désignation",
    },
    {
      dataField: "description",
      text: "Description",
    },
  ];

  useEffect(() => {
    setTableReload(currentTime)
  },[commandeId])

  const filename = ""+currentTime();
  const exportToExcel = () => {
    (async () => {
      await ExportToExcelTable("suivi_prestation_instrument_"+filename, new SuiviPrestationModel(), '/by/commande/'+commandeId, columns, {per_page:"all"});
    })()
  }

  return (
    <div className='modal fade' id='prestation_modal' data-bs-backdrop="static" aria-hidden='true'>
      <div className='modal-dialog mw-1000px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-orange' data-bs-dismiss='modal' onClick={() => modalActions.close()}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-2 mx-xl-0 pt-0 pb-15'>
            <div className='text-center mb-5'>
              <h1 className='mb-3'>Liste des instruments de la commande</h1>
            </div>

            {/* begin::Body */}
            <div className='card-body py-0'>
              <div className="d-flex justify-content-end mb-4">
                {
                  <a href={void(0)} onClick={() => exportToExcel()} className="btn btn-sm btn-light-success">
                    <i className="fas fa-file-excel"></i> Exporter
                  </a>
                }
              </div>
              {/* begin::Table container */}
              <div className='table-responsive'>
                <DataTable
                  tableReload={tableReload}
                  dataModel={new SuiviPrestationModel()}
                  path={'/by/commande/'+commandeId}
                  columns={columns}
                />
              </div>
              {/* end::Table container */}
            </div>
            {/* begin::Body */}
          </div>
        </div>
      </div>
    </div>
  )
}

export {PrestationModal}
