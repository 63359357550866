import Model from "../Model";
import * as _helpers from "../../_metronic/helpers";

class DashBoardModel extends Model {

    private prefix = "commercials/dashboard"

    getWithParams(path: any, params: any) {
        path = this.prefix + path
        return this.withParams(path, params)
    }

    getSectionCustomersCount() {
        let path = '/section-customers-count'
        return this.getWithParams(path, {})
    }

    getSectionOrdersLastActivities() {
        let path = '/section-orders-last-activities'
        return this.getWithParams(path, {})
    }

    getSectionInvoicesLastActivities() {
        let path = '/section-invoices-last-activities'
        return this.getWithParams(path, {})
    }

    getSectionGraphQuotes() {
        let path = '/section-graph-quotes'
        return this.getWithParams(path, {})
    }

    getSectionGraphOrders() {
        let path = '/section-graph-orders'
        return this.getWithParams(path, {})
    }

    getSectionTotalInvoices() {
        let path = '/section-total-invoices'
        return this.getWithParams(path, {})
    }

    getSectionGraphInvoices() {
        let path = '/section-graph-invoices'
        return this.getWithParams(path, {})
    }

    getSectionTotalRecoveries() {
        let path = '/section-total-recoveries'
        return this.getWithParams(path, {})
    }

    getSectionGraphRecoveries() {
        let path = '/section-graph-recoveries'
        return this.getWithParams(path, {})
    }

    getSectionCommercialsActivities() {
        let path = '/section-commercials-activities'
        return this.getWithParams(path, {})
    }

}

export default DashBoardModel
