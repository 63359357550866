/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {CheckAccess} from '../../../../../Utilities'

import {StatusInSuiviPlanning} from './StatusInSuiviPlanning'

type Props = {
    className: string,
    bgHeaderColor: string,
}

const StatusInSuiviPlanningView: React.FC<Props> = ({className, bgHeaderColor}) => {
    return (
        <>
            <div className={`card ${className}`}>
                {/* begin::Header */}
                <div className={`card-header border-0 py-5 bg-${bgHeaderColor}`}>
                </div>
                {/* end::Header */}

                {/* begin::Body */}
                <div className='card-body p-0'>
                    {/* begin::Memos */}
                    <div className='card-p mt-n20 position-relative'>
                        {/* begin::Row */}
                        <div className='row g-0'>
                            <StatusInSuiviPlanning className={className} />
                        </div>
                        {/* end::Row */}
                    </div>
                    {/* end::Memos */}
                </div>
                {/* end::Body */}
            </div>
        </>
    )
}

export {StatusInSuiviPlanningView}