import {FC} from 'react'

import {StatusInSuiviPlanningView} from "./status/StatusInSuiviPlanningView";
import {PlanningsView} from './PlanningsView'
import {UnplannedOrdersView} from './unplanned_orders/UnplannedOrdersView'

const Plannings: FC = () => {
  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 gx-xl-12 mb-5'>
        <div className='col-xxl-12'>
          <StatusInSuiviPlanningView className='card-xl-stretch mb-xxl-8' bgHeaderColor={'orange'}/>
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-12'>
        <div className='col-xxl-12'>
          <PlanningsView className='mb-5 mb-xl-8' />
        </div>

        <div className='col-xxl-12'>
          <UnplannedOrdersView className='mb-5 mb-xl-8' />
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export {Plannings}
