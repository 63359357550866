/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import * as Yup from 'yup';
import clsx from 'clsx'
import {useFormik} from 'formik'

import {currentTime, responseApi} from '../../../../Utilities'
import {notify} from "../../../../../services/Toastify/Toastify";

import PlanningModel from '../../../../../models/se/PlanningModel';
import {priorities_type} from "../../../../Types";
import {useSafeState} from "../../../../../services/Hooks/useSafeState";

type Props = {
    planningId: any
    modalActions: {
        open: (id: any) => void;
        close: () => void;
    }
    setTableReload: any,
}

const PlanningModal: React.FC<Props> = ({planningId, modalActions, setTableReload}) => {
    const formInitial = {
        date_fin_planning: '',
        priority_planning: '',
        operateur_planning: '',
        add_todo_planning: '',
    }
    const [formState, setFormState] = useSafeState(formInitial)
    const [loading, setLoading] = useState(false)

    // @ts-ignore
    const isAddMode = !planningId;

    const findPlanning = async (planningId: bigint) => {
        let dataModel = new PlanningModel()
        const {data} = await dataModel.find(planningId)
        if (data.type === "success") {
            setFormState({
                ...formState,
                date_fin_planning: data.result.date_fin_planning || "",
                priority_planning: data.result.priority_planning || "",
                operateur_planning: data.result.operateur_planning || "",
                add_todo_planning: data.result.add_todo_planning || "",
            })
        } else
            setFormState(formInitial)
    }

    const formSchema = Yup.object().shape({
        date_fin_planning: Yup.string()
            .required('Ce champ est requis !'),
        priority_planning: Yup.string()
            .required('Ce champ est requis !'),
        operateur_planning: Yup.string()
            .required('Ce champ est requis !'),
        add_todo_planning: Yup.string().nullable(),
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formState,
        validationSchema: formSchema,
        onSubmit: (values, {setSubmitting, resetForm}) => {
            setLoading(true)
            setTimeout(async () => {
                let response = responseApi;
                const planning = new PlanningModel()
                if (!isAddMode) {
                    const {data} = await planning.update(planningId, values)
                    response = data;
                } else {
                    const {data} = await planning.add(values)
                    response = data;
                }
                if (response.type !== null && response.message !== null) {
                    notify(response.type, response.message)
                    if (response.type === "success") {
                        if (!planningId)
                            resetForm();
                        setTableReload(currentTime)
                    }
                } else {
                    notify("error", "Opération non effectuée | catch error")
                }
                setLoading(false)
                setSubmitting(false)
            }, 1000)
        },
    })

    const initForm = () => {
        if (planningId) {
            findPlanning(planningId).then((response) => {
            })
        } else
            setFormState(formInitial)
    }

    useEffect(() => {
        initForm()
    }, [planningId])


    const onChangeField = (event: any) => {
        const {name, value, type} = event.target
        //formik.setFieldValue(name, value)
        setFormState({
            ...formState,
            [name]: type === "checkbox" ? event.target.checked : value
        })
    }

    return (
        <>
            {
                //console.log(formState)
            }
            <div className='modal fade' id='planning_modal' data-bs-backdrop="static" aria-hidden='true'>
                <div className='modal-dialog mw-800px'>
                    <div className='modal-content'>
                        <div className='modal-header pb-0 border-0 justify-content-end'>
                            <div className='btn btn-sm btn-icon btn-active-color-orange' data-bs-dismiss='modal'
                                 onClick={() => modalActions.close()}>
                                <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1'/>
                            </div>
                        </div>

                        <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                            <div className='text-center mb-13'>
                                <h1 className='mb-3'>Modification de la planification</h1>
                            </div>

                            <form onSubmit={formik.handleSubmit} className='form w-100' noValidate>
                                {/* begin::Form group */}
                                <div className='row mb-4'>
                                    <label className='col-lg-4 col-form-label'>
                                        <span className='required'>Date de fin</span>
                                    </label>
                                    <div className='col-lg-8'>
                                        <input type='date' {...formik.getFieldProps('date_fin_planning')} name='date_fin_planning'
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.date_fin_planning && formik.errors.date_fin_planning},
                                                {'is-valid': formik.touched.date_fin_planning && !formik.errors.date_fin_planning,}
                                            )}
                                            value={formState?.date_fin_planning}
                                            onChange={onChangeField}
                                        />
                                        {formik.touched.date_fin_planning && formik.errors.date_fin_planning && (
                                            <div className='fv-plugins-message-container'>
                                                <span role='alert'>{formik.errors.date_fin_planning}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* end::Form group */}

                                {/* begin::Form group */}
                                <div className='row mb-4'>
                                    <label className='col-lg-4 col-form-label'>
                                        <span className='required'>Priorité</span>
                                    </label>
                                    <div className='col-lg-8'>
                                        <select {...formik.getFieldProps('priority_planning')}
                                            name='priority_planning'
                                            className='form-select form-select-solid form-select-lg fw-bold'
                                            value={formState?.priority_planning}
                                            onChange={onChangeField}
                                        >
                                            <option value=''>-- Sélectionnez la priorité --</option>
                                            {
                                                priorities_type && priorities_type.map((option, optionIndex) => {
                                                    return (
                                                        <option key={"option_priorities" + optionIndex}
                                                                value={option['value']}>
                                                            {option['label']}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {formik.touched.priority_planning && formik.errors.priority_planning && (
                                            <div className='fv-plugins-message-container'>
                                                <span role='alert'>{formik.errors.priority_planning}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* end::Form group */}

                                {/* begin::Form group */}
                                <div className='row mb-4'>
                                    <label className='col-lg-4 col-form-label'>
                                        <span className='required'>Observation</span>
                                    </label>
                                    <div className='col-lg-8'>
                                        <textarea {...formik.getFieldProps('operateur_planning')} rows={5} name='operateur_planning' placeholder='observation ...'
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.operateur_planning && formik.errors.operateur_planning},
                                                {'is-valid': formik.touched.operateur_planning && !formik.errors.operateur_planning,}
                                            )}
                                            value={formState?.operateur_planning}
                                            onChange={onChangeField}
                                        ></textarea>
                                        {formik.touched.operateur_planning && formik.errors.operateur_planning && (
                                            <div className='fv-plugins-message-container'>
                                                <span role='alert'>{formik.errors.operateur_planning}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* end::Form group */}

                                {/* begin::Form group */}
                                <div className='row mb-4'>
                                    <label className='col-lg-4 form-check-labell'>
                                        <span className=''>Afficher dans le programme</span>
                                    </label>
                                    <div className='col-lg-8'>
                                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                                            <input checked={formState.add_todo_planning > 0}
                                                {...formik.getFieldProps('add_todo_planning')}
                                                name='add_todo_planning'
                                                className='form-check-input' type='checkbox'
                                                onChange={onChangeField}
                                            />
                                            <span className='form-check-label'>Activé</span>
                                        </label>
                                    </div>
                                </div>
                                {/* end::Form group */}

                                {/* begin::Action */}
                                <div className='text-center mt-8'>
                                    <button type='submit' id='kt_sign_in_submit'
                                            disabled={formik.isSubmitting || !formik.isValid}
                                            className='btn btn-lg btn-orange w-100 mb-5'>
                                        {!loading &&
                                        <span className='indicator-label text-uppercase'>Enregistrer</span>}
                                        {loading && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                                        )}
                                    </button>
                                </div>
                                {/* end::Action */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {PlanningModal}
