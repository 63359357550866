/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import {RootState} from '../../../../setup/redux/Store'
import {CheckAccess} from '../../../../app/Utilities';
import AuthModel from "../../../../models/AuthModel";
import {actions as appActions} from "../../../../setup/redux/AppRedux";
import {actions as authActions} from "../../../../app/auth";

//import {Languages} from './Languages'

const HeaderUserMenu: FC = () => {
    const currentUser = useSelector((state: RootState) => state.appReducer.currentUser, shallowEqual)
    const dispatch = useDispatch()
    const logout = () => {
        const log = new AuthModel();
        log.logout().then(async (response) => {
            await dispatch(appActions.destroyAppState())
            await dispatch(authActions.logoutSuccess())
        })
    }
    return (
        <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px' data-kt-menu='true'>
            <div className='menu-item px-3'>
                <div className='menu-content d-flex align-items-center px-3'>
                    <div className='symbol symbol-50px me-5'>
                        <img alt='Logo' src={currentUser?.photo_pers || toAbsoluteUrl('/blank.png?v=1')}/>
                    </div>

                    <div className='d-flex flex-column'>
                        <div className='fw-bolder d-flex align-items-center fs-6'>
                            {currentUser?.nom}
                        </div>
                        <a href={void(0)} className='fw-bold text-muted text-hover-success text-success fs-7'>
                            {currentUser?.fonction}
                        </a>
                    </div>
                </div>
            </div>

            <div className='separator my-2'></div>

            <div className='menu-item px-5'>
                <Link to={'/my-account'} className='menu-link px-5'>
                    Mon compte
                </Link>
            </div>

            <div className='menu-item px-5'>
                <Link to={'/my-account/departments'} className='menu-link px-5'>
                    Mes départements
                </Link>
            </div>

            {
                CheckAccess('dashboard-access', 'suivi_qualif') &&
                <div className='menu-item px-5'>
                    <Link to={'/my-account/qualifications'} className='menu-link px-5'>
                        Mes qualifications
                    </Link>
                </div>
            }

            <div className='separator my-2'></div>

            {/* <Languages/> */}

            <div className='menu-item px-5'>
                <a href={void(0)} onClick={logout} className='menu-link px-5'>
                    Déconnexion
                </a>
            </div>
        </div>
    )
}

export {HeaderUserMenu}
