import Model from "../Model";
import * as _helpers from "../../_metronic/helpers";

class SettingModel extends Model {

    private prefix = "instruments/settings"

    getWithParams(path:any, params:any) {
        path = this.prefix + path
        return this.withParams(path, params)
    }

    getAll(params: any) {
        let path = this.prefix
        return this.withParams(path, params)
    }

    getSetting() {
        return this.get(this.prefix)
    }

    saveDaysForRacNotif(data: {}) {
        return this.post(this.prefix + `/days-for-rac-notif`, data)
    }

    saveSubModules(data: {}) {
        return this.post(this.prefix + `/submodules`, data)
    }

    saveLifeSheetPrintConfig(data: {}) {
        return this.post(this.prefix + `/lifesheet-print-config`, data)
    }

}

export default SettingModel
