/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import parse from "html-react-parser";

import {CheckAccess, currentTime} from '../../../../Utilities';
import DataTable from "../../../../../services/Table/DataTable";
import {ExportToExcelTable} from "../../../../../services/Excel/ExportToExcel";

import DashBoardModel from "../../../../../models/gp/DashBoardModel";

type Props = {
  className: string,
  cdi : any,
}

const QualificationsBoard: React.FC<Props> = ({className,cdi}) => {

  const [tableReload, setTableReload] = useState(true)

  const columns = [
    {
      dataField: "person.nom",
      text: "Nom & prénoms",
      sort: true,
      search: {},
      formatter : (cellContent : any, row : any,rowIndex : any) => {
        return(<>{row.person_nom}</>)
      }
    },
    {
      dataField: "niveau_quali",
      text: "Niveau de qualification",
      sort: true,
      search: {},
    },

    {
      dataField: "domaine.design_dom",
      text: "Domaines",
      sort: true,
      search: {},
      formatter : (cellContent : any, row : any,rowIndex : any) => {
        return(<>{row.domaine_design_dom}</>)
      }
    },
    {
      dataField: "date_requali",
      text: "Date prochaine qualification",
      sort: true,
      search: {},
      formatter : (cellContent : any, row : any,rowIndex : any) => {
        return(<>{parse(row.date_requali_span)}</>)
      }
    },
  ];

  const filename = ""+currentTime();
  const exportToExcel = () => {
    (async () => {
      await ExportToExcelTable("personnel_qualification_proche_depassee_"+filename, new DashBoardModel(), "/component/lists/qualifications/near-out-date", columns, {per_page:"all"});
    })()
  }

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-2 mb-1'>
              Tableau du personnel dont la date de qualification est proche ou dépassée
            </span>
          </h3>
          <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover'>
            {
              CheckAccess("dashboard-access") &&
              <a href={void(0)} onClick={() => exportToExcel()} className="btn btn-sm btn-light-success ms-2">
                <i className="fas fa-file-excel"></i> Exporter
              </a>
            }
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            <DataTable
              tableReload={tableReload}
              dataModel={new DashBoardModel()}
              columns={columns}
              path='/component/lists/qualifications/near-out-date'
            />
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {QualificationsBoard}
