/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'

type Props = {
    className: string,
    count : any,
}

const CountNewCustomersHasQuoteAndOrderInCurrentYear: React.FC<Props> = ({className, count}) => {
    
    return (
        <>
            {/* begin::Col */}
            <div className='col bg-light-success px-6 py-8 rounded-2 mb-7 ms-2 me-2'>
                <div className='d-flex align-items-center flex-column text-center'>
                    <KTSVG path='/media/icons/duotone/Communication/Adress-book2.svg' className='svg-icon-2x svg-icon-success d-block my-2 me-2'/>

                    <div className='fw-bold fw-bolder fs-5 text-success mt-3'>
                        Nombre de nouveaux clients
                    </div>

                    <div className='fw-bold fw-bolder fs-1 text-success mt-3'>
                        {count}
                    </div>
                </div>
            </div>
            {/* end::Col */}
        </>
    )
}

export {CountNewCustomersHasQuoteAndOrderInCurrentYear}
