/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CheckAccess} from '../../../../../Utilities'

import {CountRaccordementOutOrUpDateAllDep} from "./CountRaccordementOutOrUpDateAllDep"
import {CountVerificationOutOrUpDateAllDep} from "./CountVerificationOutOrUpDateAllDep"
import {CountQualificationOutOrUpDateAllDep} from "./CountQualificationOutOrUpDateAllDep"
import {EquipmentAvailablityStatusAllDep} from "./EquipmentAvailablityStatusAllDep"
import {PrevisionRaccordementCostAllDep} from "./PrevisionRaccordementCostAllDep"

type Props = {
  className: string
}

const StatusAllDepartmentView: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-2 text-uppercase mb-1'>
            Tableau des Etats
          </span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a href='#tab_raccordement' className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder fs-6 px-4 ms-1 me-1' data-bs-toggle='tab'>
                Raccordements
              </a>
            </li>

            {
              CheckAccess('dashboard-access', 'suivi_verif') &&
              <li className='nav-item'>
                <a href='#tab_verification' className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder fs-6 px-4 ms-1 me-1' data-bs-toggle='tab'>
                  Vérifications internes
                </a>
              </li>
            }

            {
              CheckAccess('dashboard-access', 'suivi_qualif') &&
              <li className='nav-item'>
                <a href='#tab_qualification' className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder fs-6 px-4 ms-1 me-1' data-bs-toggle='tab'>
                  Qualifications du personnel
                </a>
              </li>
            }

            {/* <li className='nav-item'>
              <a href='#tab_equipment' className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder fs-6 px-4 ms-1 me-1' data-bs-toggle='tab'>
                Equipements
              </a>
            </li>

            <li className='nav-item'>
              <a href='#tab_prestation' className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder fs-6 px-4 ms-1 me-1' data-bs-toggle='tab'>
                Analyse des coûts
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div id='tab_raccordement' className='tab-pane fade show active'>
            <CountRaccordementOutOrUpDateAllDep />
          </div>
          {/* end::Tap pane */}

          {/* begin::Tap pane */}
          <div id='tab_verification' className='tab-pane fade'>
            <CountVerificationOutOrUpDateAllDep />
          </div>
          {/* end::Tap pane */}
          
          {/* begin::Tap pane */}
          <div id='tab_qualification' className='tab-pane fade'>
            <CountQualificationOutOrUpDateAllDep />
          </div>
          {/* end::Tap pane */}

          {/* begin::Tap pane */}
          <div id='tab_equipment' className='tab-pane fade'>
            <EquipmentAvailablityStatusAllDep />
          </div>
          {/* end::Tap pane */}

          {/* begin::Tap pane */}
          <div id='tab_prestation' className='tab-pane fade'>
            <PrevisionRaccordementCostAllDep />
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {StatusAllDepartmentView}
