import {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {actions} from '../../../../setup/redux/AppRedux'
import {notify} from '../../../../services/Toastify/Toastify'

import GlobalModel from '../../../../models/GlobalModel'
import {useSafeState} from "../../../../services/Hooks/useSafeState";

export const DepartmentSelect = (props) => {
    let history = useHistory()
    const dispatch = useDispatch()
    const [departments, setDepartments] = useSafeState([])
    /*
     const getAllDepartment = async () => {
         const {data} = await new GlobalModel().getDepartments()
         if (data.type === 'success') {
             setDepartments(prevState => data.result.data)
         } else
             setDepartments(prevState => [])
     }

     /* usct(() => {
        (async () => {
          await getAllDepartment()
        })()
      }, [])*/

    const getAllDepartment = async () => {
        let dataModel = new GlobalModel()
        const {data} = await dataModel.getDepartments()
        if (data.type === "success") {
            return data.result.data
            //setDepartments( data.result.data)
        } else {
            //setDepartments( [])
            return []
        }
    }

    useEffect(() => {
        let isCancelled = false;
        getAllDepartment().then((response) => {
            if (!isCancelled)
                setDepartments(prevState => response)
        })
        return () => {
            isCancelled = true
        };
    }, [])

    const findDepartment = async (departmentId) => {
        let dataModel = new GlobalModel()
        const {data} = await dataModel.findDep(departmentId)
        return data
    }

    const onChange = async (e) => {
        const {value} = e.target
        const data = await findDepartment(value)
        if (data.type === 'success') {
            await dispatch(actions.saveCurrentDepartment({
                cdi: data.result.id,
                cdiDesign: data.result.design_dep,
            }))
            notify('success', 'Changement de département effectué avec succès')
            history.push('/instrument/dashboard')
        }
    }

    const cdi = useSelector((state) => state.appReducer.cdi, shallowEqual)

    return (
        <>
            <div className='menu-item me-lg-3'>
                <select value={cdi} onChange={(e) => onChange(e)} style={{height: '38px'}}
                        className="form-control text-uppercase">
                    {
                        departments && departments.map((row) => {
                            return (
                                <option key={row.id + '_option'} value={row.id}>
                                    {row.design_dep}
                                </option>
                            )
                        })
                    }
                </select>
            </div>
        </>
    )
}