/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {CheckAccess} from '../../../../Utilities'

import {CountRaccordementOutOrUpDate} from './CountRaccordementOutOrUpDate'
import {CountVerificationOutOrUpDate} from './CountVerificationOutOrUpDate'
import {CountQualificationOutOrUpDate} from './CountQualificationOutOrUpDate'
import {PrevisionRaccordementCost} from './PrevisionRaccordementCost'
import {EquipmentAvailablityStatus} from './EquipmentAvailablityStatus'

type Props = {
    className: string,
    bgHeaderColor: string,
    cdi: any
}

const StatusView: React.FC<Props> = ({className, cdi, bgHeaderColor}) => {
    return (
        <>
            <div className={`card ${className}`}>
                {/* begin::Header */}
                <div className={`card-header border-0 py-5 bg-${bgHeaderColor}`}>
                </div>
                {/* end::Header */}

                {/* begin::Body */}
                <div className='card-body p-0'>
                    {/* begin::Memos */}
                    <div className='card-p mt-n20 position-relative'>
                        {/* begin::Row */}
                        <div className='row g-0'>
                            <CountRaccordementOutOrUpDate cdi={cdi} className={className}/>
                            
                            {
                                CheckAccess('dashboard-access', 'suivi_verif') &&
                                <CountVerificationOutOrUpDate cdi={cdi} className={className}/>
                            }
                        </div>
                        {/* end::Row */}

                        {/* begin::Row */}
                        {
                            CheckAccess('dashboard-access', 'suivi_qualif') &&
                            <div className='row g-0'>
                                <CountQualificationOutOrUpDate cdi={cdi} className={className}/>
                            </div>
                         }

                        <div className='row g-0'>
                            <EquipmentAvailablityStatus cdi={cdi} className={className}/>

                            <PrevisionRaccordementCost cdi={cdi} className={className}/>
                        </div>
                        {/* end::Row */}
                    </div>
                    {/* end::Memos */}
                </div>
                {/* end::Body */}
            </div>
        </>
    )
}

export {StatusView}