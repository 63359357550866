/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import DashBoardModel from "../../../../../models/gp/DashBoardModel";

type Props = {
    className: string,
    cdi: any,
}

const initStatus = {
    previous: "",
    previous_format_money: "",
    current: "",
    current_format_money: "",
    next: "",
    next_format_money: "",
}

const PrevisionRaccordementCost: React.FC<Props> = ({className, cdi}) => {

    const currentYear = new Date().getFullYear();

    const [status, setStatus] = useState(initStatus)

    const getStatus = async () => {
        let dataModel = new DashBoardModel()
        const {data} = await dataModel.getComponentPrevisionRaccordementCost()
        //console.log(data);
        if (data.type === "success") {
            setStatus(prevState => {
                return {
                    previous: data?.result?.previous,
                    previous_format_money: data?.result?.previous_format_money,
                    current: data?.result?.current,
                    current_format_money: data?.result?.current_format_money,
                    next: data?.result?.next,
                    next_format_money: data?.result?.next_format_money,
                }
            })
        } else {
            setStatus(initStatus)
        }
    }

    useEffect(() => {
        getStatus();
    }, [cdi])


    return (
        <>
            {/* begin::Col */}
            <div className='col bg-light-warning px-6 py-8 rounded-2 mb-7 ms-2 me-2'>
                <div className='d-flex align-items-center'>
                    <KTSVG path='/media/icons/duotone/Media/Equalizer.svg'
                           className='svg-icon-3x svg-icon-warning d-block my-2 me-2'/>

                    <div className='text-warning fw-bold fw-bolder fs-2'>
                        Analyse des coûts de prestation
                    </div>
                </div>

                <div className='d-flex align-items-center flex-column mt-5'>
                    <div className='d-flex justify-content-between w-100 mt-auto'>
                        <span className='fw-bold fs-6 text-gray-500'>
                            Dépense de l'année précédente ({currentYear - 1}) :
                        </span>
                        <span className='fw-bold fs-3'>
                            {status.previous_format_money}
                        </span>
                    </div>

                    <div className='mx-3 w-100'>
                        <hr className='rounded h-2px'/>
                    </div>

                    <div className='d-flex justify-content-between w-100 mt-auto'>
                        <span className='fw-bold fs-6 text-gray-500'>
                            Estimation de l'année en cours ({currentYear}) :
                        </span>
                        <span className='fw-bold fs-3'>
                            {status.current_format_money}
                        </span>
                    </div>

                    <div className='mx-3 w-100'>
                        <hr className='rounded h-2px'/>
                    </div>

                    <div className='d-flex justify-content-between w-100 mt-auto'>
                        <span className='fw-bold fs-6 text-gray-500'>
                            Estimation de l'année prochaine ({currentYear + 1}) :
                        </span>
                        <span className='fw-bold fs-3'>
                            {status.next_format_money}
                        </span>
                    </div>
                </div>
            </div>
            {/* end::Col */}
        </>
    )
}

export {PrevisionRaccordementCost}
