/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {toAbsoluteUrl} from '../../_metronic/helpers'

import {Login} from './Login'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-light-dark'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/logos/login-bg-img.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <a href={void(0)} className='mb-5'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo2.png?v=1')} className='h-65px logo' />
        </a>
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Switch>
            <Route path='/auth/login' component={Login} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </div>
      </div>
      {/* end::Content */}
      
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <span className='text-dark fw-bold me-2'>
            {new Date().getFullYear()} &copy;
          </span>
          <a href={void(0)} className='menu-link text-gray-800 text-hover-primary text-uppercase me-2'>
            Smart Sentino 2.0
          </a>
          <span className="me-2">
            Powered by
          </span>
          <a href='https://www.process-instruments.ma/' target='_blank' rel="noopener noreferrer" className='text-gray-800 text-hover-primary text-uppercase me-2'>
            Process Instruments,
          </a>
          <span className="me-2">
            Tous droits réservés.
          </span>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}
