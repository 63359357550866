import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notify = (type: any, content: any) => {

    switch (type) {
        case 'success' :
            toast.success(content, {
                position: toast.POSITION.TOP_RIGHT
            });
            break;
        case 'error' :
            toast.error(content, {
                position: toast.POSITION.TOP_RIGHT
            });
            break;
        case 'info' :
            toast.info(content, {
                position: toast.POSITION.TOP_RIGHT
            });
            break;

        default :
            toast(content, {
                position: toast.POSITION.TOP_RIGHT
            });
    }
};
