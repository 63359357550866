import Model from "./Model";

class GlobalModel extends Model {

    private prefix = "globals"

    checkSociety() {
        return this.get(this.prefix + `/check-society`)
    }

    getDepartments() {
        return this.get(this.prefix + `/departments`)
    }

    findDep(id: bigint) {
        return this.get(this.prefix + `/departments/find/${id}`)
    }

    getCurrencies() {
        return this.get(this.prefix + `/currencies`)
    }

    getCountriesDataOptions(params: any) {
        let path = this.prefix + `/countries-data-options`
        return this.withParams(path, params)
    }

    getCountriesData(params: any) {
        let path = this.prefix + `/countries`
        return this.withParams(path, params)
    }

}

export default GlobalModel
